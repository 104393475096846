const Hero = ({ bgImg, heading, para }) => {
  return (
    <main
      style={{
        background: `url(${bgImg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
      className="relative p-[5%] flex-col justify-center h-fit "
    >
      <div className="absolute top-0 left-0 right-0 bottom-0 bg-[#11071f98] z-0"></div>
      <h1 className="font-semibold text-3xl lg:text-4xl xl:lg:text-5xl my-4 font-Montserrat pr-8 text-white relative z-10 max-w-4xl">
        {heading}
      </h1>
      <p className="text-base relative z-10 lg:text-lg pr-8 text-white opacity-70 font-Montserrat max-w-3xl">
        {para}
      </p>
    </main>
  );
};
export default Hero;
