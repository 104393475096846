import AboutISA from '../../Components/Services/AboutISA';
import Hero from '../../Components/Services/Hero';
import { PiHandshakeLight } from 'react-icons/pi';
import { GrDocumentPerformance } from 'react-icons/gr';
import { GiSpeedometer } from 'react-icons/gi';
import { HiCubeTransparent } from 'react-icons/hi';
import ImageWithText from '../../Components/Services/ImageWithText';
import skyscraper from '../../assets/Images/Services/application.png';
import CloudImg from '../../assets/Images/Services/Innovative_Application.webp';
import Transforming from '../../assets/Images/Services/Applications_Modernization.webp';
import applicationLogo from '../../assets/Images/Services/application-logo.png';
import cloud_strategy from '../../assets/Images/Services/Managed_Applications.webp';
import Multicloud from '../../assets/Images/Services/Software_Development.webp';
import Public_Cloud from '../../assets/Images/Services/Testing.webp';
import Cloud_Platforms from '../../assets/Images/Services/ISA_Software.webp';
import HowtoFindPlacement from '../../Components/Home/HowtoFindPlacement';
import ServiceBanner from '../../Components/Services/ServiceBanner';
import heroBg from '../../assets/Images/Services/Reimagine.webp';

const aboutISA_Data = [
  {
    icon: <PiHandshakeLight size={60} color="#E578FF" />,
    heading: '',
    des: "Customers utilizing ISA's intelligent automation solutions achieve significant reductions in maintenance costs.",
  },
  {
    icon: <GrDocumentPerformance size={60} color="#E578FF" />,
    heading: '',
    des: "ISA's dedicated team of professionals delivers expertise in custom and enterprise applications, driving efficiency and innovation for our clients.",
  },
  {
    icon: <GiSpeedometer size={60} color="#E578FF" />,
    heading: '',
    des: 'Our emphasis on Agile Development and DevOps Services enhances operational agility and speeds up time to market with integrated, data-driven systems.',
  },
  {
    icon: <HiCubeTransparent size={60} color="#E578FF" />,
    heading: '',
    des: 'We empower businesses to optimize their processes and improve overall performance through tailored solutions and advanced technology integration.',
  },
];

const Application = () => {
  return (
    <>
      <Hero
        bgImg={heroBg}
        heading="Reimagine your business and transform applications for growth"
        para="Transform your business with innovative application strategies. Modernize, optimize, and align your applications to enhance efficiency, reduce costs, and drive business agility."
      />
      <ImageWithText
        img={skyscraper}
        imgFirst={true}
        // heading="Harnessing Insurance Ecosystems to Navigate Change"
        pragraphContents={
          <>
            <p className="font-BaiJamjuree text-base lg:text-xl my-4">
              ISA is your trusted partner for simplifying, modernizing, and
              accelerating mission-critical applications to support business
              agility and growth. Leverage the latest digital platforms with
              custom applications, ensuring resilience while launching new
              products and entering markets with minimal disruption.
            </p>
            <p className="font-BaiJamjuree text-base lg:text-xl my-4">
              Our services enhance customer and employee experiences through
              end-to-end personalization and data insights, reducing costs and
              creating efficient business processes that transform your
              operations. Discover how to reimagine your business with ISA.
            </p>
          </>
        }
      />
      <AboutISA
        heading="About ISA in Application Services"
        iconData={aboutISA_Data}
      />
      <ImageWithText
        img={CloudImg}
        imgFirst={true}
        heading="Innovative Application Services"
        pragraphContents={
          <p className="font-BaiJamjuree text-base lg:text-xl my-4">
            ISA delivers innovative application services that simplify,
            modernize, and accelerate mission-critical systems. Using automation
            and AI, our team optimizes application development and management,
            enhancing agility and ensuring smooth operations. Through close
            collaboration with clients, we overcome challenges and drive
            sustainable business growth in a dynamic technological landscape.
          </p>
        }
      />
      <ServiceBanner
        heading="Optimized Application Services"
        parag="ISA offers tailored application solutions that enhance efficiency and align with strategic business objectives, empowering organizations to succeed in a competitive landscape."
        logo={applicationLogo}
      />
      <div className="p-[5%] pb-0">
        {/* <hr className="h-px bg-white70 mb-4" /> */}
        <h1 className="opacity-100 font-BaiJamjuree text-2xl lg:text-4xl font-semibold">
          Services
        </h1>
      </div>
      <ImageWithText
        img={Transforming}
        imgFirst={true}
        heading="Applications Modernization"
        pragraphContents={
          <p className="font-BaiJamjuree text-base lg:text-xl my-4">
            ISA uses intelligent automation and continuous optimization to
            reduce costs and simplify IT systems. We quickly identify
            modernization needs, deploying scalable solutions that enhance speed
            while minimizing risks and costs.
          </p>
        }
      />
      <ImageWithText
        img={Multicloud}
        imgFirst={false}
        heading="Software Development"
        pragraphContents={
          <p className="font-BaiJamjuree text-base lg:text-xl my-4">
            ISA’s Software Development services empower businesses to enhance
            agility, improve time to market, reduce costs, and boost customer
            experiences, while fostering growth through innovation.
          </p>
        }
      />
      <ImageWithText
        img={cloud_strategy}
        imgFirst={true}
        heading="Managed Applications"
        pragraphContents={
          <p className="font-BaiJamjuree text-base lg:text-xl my-4">
            ISA enables clients to shift IT spending from operations to
            innovation with a results-driven application transformation and
            management model, allowing businesses to invest in digital
            strategies and accelerate growth.
          </p>
        }
      />
      <ImageWithText
        img={Public_Cloud}
        imgFirst={false}
        heading="Testing"
        pragraphContents={
          <p className="font-BaiJamjuree text-base lg:text-xl my-4">
            Ensure faster, more efficient, and cost-effective solutions with
            ISA's comprehensive Testing as a Service. Our approach provides a
            complete view of application quality—covering functionality,
            performance, and security—while leveraging automation, Agile, and
            DevSecOps to accelerate time to value.
          </p>
        }
      />
      <ImageWithText
        img={Cloud_Platforms}
        imgFirst={true}
        heading="ISA Software"
        pragraphContents={
          <p className="font-BaiJamjuree text-base lg:text-xl my-4">
            Optimize and streamline operations, reduce time to market, and
            enhance customer engagement with ISA's advanced software solutions.
            Leveraging industry expertise and global capabilities, our software
            automates key business processes, supporting efficient and scalable
            operations.
          </p>
        }
      />
      <HowtoFindPlacement />
    </>
  );
};
export default Application;
