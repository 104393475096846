// export const modeReducer = (state = {}, action) => {
//   switch (action.type) {
//     case "DARK_MODE":
//       return {
//         ...state,
//         darkMode: !state.darkMode,
//       };

//     default:
//       return { ...state, darkMode: false };
//   }
// };

// reducers/modeReducer.js
import { CHANGE_MODE } from '../Actions/modeActions';

const initialState = {
  theme: localStorage.getItem('theme') || 'light',
};

const modeReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_MODE:
      localStorage.setItem('theme', action.payload);
      return {
        ...state,
        theme: action.payload,
      };
    default:
      return state;
  }
};

export default modeReducer;
