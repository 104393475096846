import { Link } from 'react-router-dom';
import { useState } from 'react';

const OfferingsCard = ({ img, heading, des, linkTo }) => {
  const [glowStyle, setGlowStyle] = useState({ top: '50%', left: '50%' });

  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setGlowStyle({
      top: `${e.clientY - rect.top}px`,
      left: `${e.clientX - rect.left}px`,
    });
  };

  return (
    <article
      className="glow-effect-container flex flex-col-reverse md:flex-row gap-4"
      onMouseMove={handleMouseMove}
      style={{
        '--glow-top': glowStyle.top,
        '--glow-left': glowStyle.left,
      }}
    >
      <div className="overflow-hidden md:max-h-[350px] md:max-w-[180px]">
        <img src={img} alt="tech image" className="w-auto h-auto" />
      </div>
      <div className="pt-3 border-t-[1px] border-[#DEDEDE]">
        <h4 className="font-BaiJamjuree font-medium text-xl lg:text-2xl">
          {heading}
        </h4>
        <p className="text-base opacity-70 py-3 mb-auto">{des}</p>
        {linkTo && (
          <Link
            to={linkTo}
            className="uppercase read-more-btn w-fit flex items-center gap-2 font-medium text-base text-[#E578FF] border-b-[1.5px] border-transparent mt-auto mb-0"
          >
            explore more
            <span className="show-arrow font-extrabold">&rarr;</span>
          </Link>
        )}
      </div>
    </article>
  );
};

export default OfferingsCard;
