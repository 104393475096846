import { CiBadgeDollar } from 'react-icons/ci';
import { GiSpeedometer } from 'react-icons/gi';
import { GrDocumentPerformance } from 'react-icons/gr';
import { HiCubeTransparent } from 'react-icons/hi';
import HowtoFindPlacement from '../../Components/Home/HowtoFindPlacement';
import AboutISA from '../../Components/Services/AboutISA';
import Hero from '../../Components/Services/Hero';
import ImageWithText from '../../Components/Services/ImageWithText';
import ServiceBanner from '../../Components/Services/ServiceBanner';
import AWS_Logo from '../../assets/Images/Services/AWS_Logo.png';
import heroBg from '../../assets/Images/Services/Business_Operations.webp';
import Transforming from '../../assets/Images/Services/Data_Center.webp';
import Public_Cloud from '../../assets/Images/Services/Mainframe.webp';
import Multicloud from '../../assets/Images/Services/Network_Connectivity.webp';
import Cloud_Platforms from '../../assets/Images/Services/Outsourcing_Services.webp';
import CloudImg from '../../assets/Images/Services/Outsourcing_Solutions.webp';
import cloud_strategy from '../../assets/Images/Services/Storage_Backup.webp';
import skyscraper from '../../assets/Images/Services/it_outsourcing.webp';

const aboutISA_Data = [
  {
    icon: <CiBadgeDollar size={60} color="#E578FF" />,
    heading: 'Cost Efficiency',
    des: 'Increased speed and cut IT operating costs up to 30% for large enterprise customers',
  },
  {
    icon: <GrDocumentPerformance size={60} color="#E578FF" />,
    heading: 'IT Modernization',
    des: 'Enabling agile, future-ready IT transformation.',
  },
  {
    icon: <GiSpeedometer size={60} color="#E578FF" />,
    heading: 'Comprehensive IT Management',
    des: 'Offering end-to-end outsourcing solutions for seamless integration and support.',
  },
  {
    icon: <HiCubeTransparent size={60} color="#E578FF" />,
    heading: 'Scalable Support',
    des: 'Flexible solutions that grow with your business needs.',
  },
];

const ITOutsourcing = () => {
  return (
    <>
      <Hero
        bgImg={heroBg}
        heading="Committed to Seamless Business Operations"
        para="Rely on ISA for secure and dependable mission-critical IT outsourcing, ensuring a seamless path to modernization."
      />
      <ImageWithText
        img={skyscraper}
        imgFirst={true}
        pragraphContents={
          <>
            <p className="font-BaiJamjuree text-base lg:text-xl my-4">
              IT operations must be secure, efficient, and forward-thinking to
              support business growth. With extensive industry experience, ISA
              delivers reliable and secure IT outsourcing services that help
              bridge the gap between traditional IT systems and modern
              innovation.
            </p>
            <p className="font-BaiJamjuree text-base lg:text-xl">
              ISA offers comprehensive IT modernization solutions tailored to
              your specific environment, whether in your own data center or in
              the cloud. Our expertise spans multiple platforms, including major
              providers like AWS, Microsoft Azure, and Google Cloud, ensuring
              flexible and adaptable solutions. Discover how ISA can provide
              disruption-free IT services to accelerate your business
              transformation and drive future success.
            </p>
          </>
        }
      />
      <AboutISA
        heading="About ISA in IT Outsourcing"
        iconData={aboutISA_Data}
      />
      <ImageWithText
        img={CloudImg}
        imgFirst={true}
        heading="Expert in IT Outsourcing Solutions"
        pragraphContents={
          <p className="font-BaiJamjuree text-base lg:text-xl my-4">
            ISA delivers comprehensive IT outsourcing services that optimize and
            manage IT infrastructure across on-premise and cloud environments,
            including AWS and Microsoft Azure. We enhance cost efficiency,
            reliability, and scalability, ensuring seamless operations that
            support business growth. Our expert team provides tailored support
            to help clients modernize their IT landscape for maximum performance
            and agility.
          </p>
        }
      />
      <ServiceBanner
        heading="Fostering Innovation and Agility in IT Outsourcing"
        parag="Enabling businesses to maximize their IT resources and enhance operational effectiveness through cutting-edge outsourcing solutions and expert guidance."
        logo={AWS_Logo}
      />
      <div className="p-[5%] pb-0">
        {/* <hr className="h-px bg-white70 mb-4" /> */}
        <h1 className="opacity-100 font-BaiJamjuree text-2xl lg:text-4xl font-semibold">
          Services
        </h1>
      </div>
      <ImageWithText
        img={Transforming}
        imgFirst={true}
        heading="Compute & Data Center"
        pragraphContents={
          <p className="font-BaiJamjuree text-base lg:text-xl my-4">
            We manage, streamline, and enhance your current IT infrastructure
            while transitioning to modern technologies through our proven
            services. Our approach involves consolidating and optimizing your
            data centers to lower costs and develop a more environmentally
            sustainable solution.
          </p>
        }
      />
      <ImageWithText
        img={cloud_strategy}
        imgFirst={false}
        heading="Storage & Backup"
        pragraphContents={
          <p className="font-BaiJamjuree text-base lg:text-xl my-4">
            Ensure seamless access to your data from anywhere, supported by our
            team of experts managing vast amounts of storage globally. Our
            advanced storage and backup solutions provide reliable access and
            include options to help mitigate the effects of ransomware attacks.
          </p>
        }
      />
      <ImageWithText
        img={Multicloud}
        imgFirst={true}
        heading="Transform Network Connectivity"
        pragraphContents={
          <p className="font-BaiJamjuree text-base lg:text-xl my-4">
            Elevate your network connectivity with services that span from
            campus to cloud. Our secure and resilient infrastructure, powered by
            software-defined networking, simplifies technology upgrades and
            allows you to provide on-demand services akin to consumer
            experiences, tailored for the modern workplace.
          </p>
        }
      />
      <ImageWithText
        img={Public_Cloud}
        imgFirst={false}
        heading="Mainframe"
        pragraphContents={
          <p className="font-BaiJamjuree text-base lg:text-xl my-4">
            leading independent mainframe services provider to manage, optimize,
            and modernize your mainframe systems.
          </p>
        }
      />
      <ImageWithText
        img={Cloud_Platforms}
        imgFirst={true}
        heading="ISA Acknowledged for Excellence in IT Outsourcing Services"
        pragraphContents={
          <p className="font-BaiJamjuree text-base lg:text-xl my-4">
            ISA is recognized for delivering innovative IT outsourcing solutions
            that integrate advanced features such as automation and proactive
            management. Our commitment to providing customized outsourcing
            services addresses diverse business needs, ensuring optimal
            performance and efficiency for clients across various industries.
          </p>
        }
      />
      <HowtoFindPlacement />
    </>
  );
};
export default ITOutsourcing;
