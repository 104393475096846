import service_banner from '../../assets/Images/Services/service_banner.png';
import isablackLogo from '../../assets/Images/ISA_Logo_black.png';
import isaLogo from '../../assets/Images/ISA_Logo.png';
import { TfiLayoutLineSolid } from 'react-icons/tfi';
import logoImg from '../../assets/Images/Services/Logo-google-cloud.png';

const ServiceBanner = ({ heading, parag, logo }) => {
  return (
    <section
      style={{
        backgroundImage: `url(${service_banner})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundImage:
          window.innerWidth >= 768 ? `url(${service_banner})` : 'none',
      }}
      className="p-[5%] mt-8 md:text-white grid grid-cols-1 md:grid-cols-2 gap-8 lg:gap-20 place-items-center bg-cover md:!bg-contain"
    >
      <article>
        <h1 className="font-BaiJamjuree text-2xl lg:text-4xl font-semibold">
          {heading}
        </h1>
        <p className="font-BaiJamjuree text-base lg:text-lg mt-4 opacity-70">
          {parag}
        </p>
      </article>
      <aside className="mr-0 ml-auto hidden md:flex items-center gap-6 justify-between overflow-hidden">
        <img
          src={window.innerWidth >= 768 ? isablackLogo : isaLogo}
          alt="isa-logo"
          className="max-w-[150px]"
        />
        <svg
          width="4"
          height="110"
          viewBox="0 0 4 110"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect y="0.32959" width="4" height="109" rx="2" fill="#11071F" />
        </svg>

        <img src={logo || logoImg} alt="logo" className="max-w-[150px]" />
      </aside>
    </section>
  );
};
export default ServiceBanner;
