import { FaFacebookF, FaXTwitter } from 'react-icons/fa6';
import { HiMiniLink } from 'react-icons/hi2';
import logo from '../../assets/Images/pink-logo.png';
import { toast } from 'react-toastify';

const ISAHeadline = () => {
  const copyUrl = () => {
    const url = window.location.href;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        toast.success('URL copied to clipboard');
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };
  return (
    <header className="mx-[5%] my-8 flex items-end justify-between pb-4 gap-4 border-b-[1px] border-white70">
      <div className="grid grid-cols-[auto_1fr] w-fit gap-x-4">
        <img
          src={logo}
          alt="ISA-logo"
          className="row-span-2 w-16 place-self-end"
        />
        <h3 className="font-Montserrat text-xl lg:text-2xl font-medium">
          ISA Consulting
        </h3>
        <p className="font-Montserrat text-base lg:text-lg">
          Welcome our new hires
        </p>
      </div>

      <aside className="flex items-center justify-end gap-4">
        <a
          href="https://www.facebook.com/profile.php?id=61555861800005"
          target="_blank"
        >
          <FaFacebookF size={25} className="opacity-70 cursor-pointer" />
        </a>

        <a href="https://twitter.com/ISAConsulting_" target="_blank">
          <FaXTwitter size={25} className="opacity-70 cursor-pointer" />
        </a>
        <button onClick={copyUrl}>
          <HiMiniLink size={25} className="opacity-70 cursor-pointer" />
        </button>
      </aside>
    </header>
  );
};
export default ISAHeadline;
