import img1 from '../../assets/Images/TeamCarousel/6.webp';
import img2 from '../../assets/Images/TeamCarousel/7.webp';
import img3 from '../../assets/Images/TeamCarousel/8.webp';
import img4 from '../../assets/Images/TeamCarousel/9.webp';
import img5 from '../../assets/Images/TeamCarousel/10.webp';
import '../Home/TeamCarouse.css';

const TeamDetialsCarousel = () => {
  return (
    <section className="carousel-container w-full overflow-hidden my-8">
      <div className="carousel-track flex">
        <img src={img1} alt="Team gathering" className="carousel-image" />
        <img src={img2} alt="Team gathering" className="carousel-image" />
        <img src={img3} alt="Team gathering" className="carousel-image" />
        <img src={img4} alt="Team gathering" className="carousel-image" />
        <img src={img5} alt="Team gathering" className="carousel-image" />
        {/* Duplicate images for infinite loop */}
        <img src={img1} alt="Team gathering" className="carousel-image" />
        <img src={img2} alt="Team gathering" className="carousel-image" />
        <img src={img3} alt="Team gathering" className="carousel-image" />
        <img src={img4} alt="Team gathering" className="carousel-image" />
        <img src={img5} alt="Team gathering" className="carousel-image" />
        {/* Duplicate images for infinite loop */}
        <img src={img1} alt="Team gathering" className="carousel-image" />
        <img src={img2} alt="Team gathering" className="carousel-image" />
        <img src={img3} alt="Team gathering" className="carousel-image" />
        <img src={img4} alt="Team gathering" className="carousel-image" />
        <img src={img5} alt="Team gathering" className="carousel-image" />
        {/* Duplicate images for infinite loop */}
        <img src={img1} alt="Team gathering" className="carousel-image" />
        <img src={img2} alt="Team gathering" className="carousel-image" />
        <img src={img3} alt="Team gathering" className="carousel-image" />
        <img src={img4} alt="Team gathering" className="carousel-image" />
        <img src={img5} alt="Team gathering" className="carousel-image" />
      </div>
    </section>
  );
};
export default TeamDetialsCarousel;
