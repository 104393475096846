import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomSpinner from './CustomSpinner';

const Loader = ({ message, path = 'login' }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [count, setcount] = useState(3);
  useEffect(() => {
    const interval = setInterval(() => {
      setcount((prevalue) => --prevalue);
    }, 1000);

    if (count === 0) {
      navigate('/');
    }
    return () => {
      clearInterval(interval);
    };
  }, [count, navigate, location, path]);

  return (
    <div className=" flex items-center justify-center flex-col h-screen">
      <CustomSpinner className="h-16 w-16 text-white" />
      <p>{message}</p>
      <h1>redirecting you in {count} second</h1>
    </div>
  );
};

export default Loader;
