import { Link } from 'react-router-dom';
import Drive_Innovation from '../../assets/Images/Drive_Innovation.webp';
import have_more_questions from '../../assets/Images/have_more_questions.webp';

const HowtoFindPlacement = () => {
  return (
    <main className="px-[5%] my-12 grid grid-cols-1 lg:grid-cols-2">
      <header className="flex flex-col md:flex-row mb-4 justify-between gap-8 col-span-full">
        <h1 className="font-BaiJamjuree text-2xl lg:text-4xl font-semibold w-full md:w-1/2 max-w-md capitalize">
          How to Find Placement for your Company
        </h1>
        <p className="text-base lg:text-lg opacity-70 w-full md:w-1/2">
          Connect with our diverse team of skilled data engineers, architects,
          developers, and project managers dedicated to delivering innovative
          solutions. Partner with us to elevate your business!
        </p>
      </header>
      <section className="border-[1px] border-[#EDB5FB] p-4 flex flex-col md:flex-row gap-4">
        <aside className="md:max-w-[180px] md:max-h-[200px] overflow-hidden">
          <img src={Drive_Innovation} alt="hands" />
        </aside>
        <div className="flex flex-col">
          <h6 className="text-[#EDB5FB] text-base lg:text-lg">
            Partner with ISA
          </h6>
          <p className="text-base md:text-xl mb-auto">
            Drive Innovation and Growth Together
          </p>
          <Link
            to="/Partner-with-us"
            className="rounded-full  bg-[--primary-color] text-[--black-bg-btn] flex gap-1 items-center arrow-effect-btn w-full md:w-fit h-fit mb-0 mt-4 py-2"
          >
            Partner with us
          </Link>
        </div>
      </section>
      <section className="border-[1px] border-[#EDB5FB] p-4 flex flex-col md:flex-row gap-4">
        <aside className="md:max-w-[180px] md:max-h-[200px] overflow-hidden">
          <img src={have_more_questions} alt="hands" />
        </aside>
        <div className="flex flex-col">
          <h6 className="text-[#EDB5FB] text-base lg:text-lg">
            Get in Touch with ISA
          </h6>
          <p className="text-base md:text-xl mb-auto">
            Still have more questions? <br />
            Contact us now.
          </p>
          <Link
            to="/businessSignup"
            className="rounded-full  bg-[--primary-color] text-[--black-bg-btn] flex gap-1 items-center arrow-effect-btn w-full md:w-fit h-fit mb-0 mt-4 py-2"
          >
            Contact us now
          </Link>
        </div>
      </section>
    </main>
  );
};
export default HowtoFindPlacement;
