import {
  ADMIN_LOGIN_FAIL,
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SUCCESS,
  CLEAR_ERRORS,
  LOAD_ADMIN_FAIL,
  LOAD_ADMIN_REQUEST,
  LOAD_ADMIN_SUCCESS,
} from '../Constants/AdminConstants';

export const adminReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_LOGIN_REQUEST:
    case LOAD_ADMIN_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
        success: false,
      };
    case ADMIN_LOGIN_SUCCESS:
    case LOAD_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        user: action.payload.user,
        success: true,
      };
    case ADMIN_LOGIN_FAIL:
    case LOAD_ADMIN_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        error: action.payload,
        success: false,
        user: null,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        success: false,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};
