import { Link } from 'react-router-dom';

const SubmissionPopup = ({ setShowPopup }) => {
  return (
    <section className="bg-[#00000061] fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center px-[5%] z-[50000]">
      <article className="text-[var(--primary-color)] bg-[--background-color] shadow-md rounded-xl w-fit max-w-xl p-4 relative z-[50000]">
        <button
          onClick={() => {
            setShowPopup(false);
          }}
          className="text-3xl absolute top-3 right-4"
        >
          x
        </button>
        <h1 className="text-xl lg:text-3xl text-[#a240be] font-semibold text-center font-BaiJamjuree my-4">
          Thank You for Your Application!
        </h1>
        <p className="text-base">
          We appreciate your interest in Partnering with ISA Consulting. Your
          data has been successfully submitted and is currently being reviewed
          by our team.
        </p>
        <p className="text-base">
          We will reach out to you promptly. Thanks for your patience.
        </p>
        <div className="flex gap-4 flex-wrap justify-center mt-4 w-full">
          <Link
            to={'/'}
            className={`rounded-full bg-[--primary-color] text-[--black-bg-btn] flex gap-1 items-center arrow-effect-btn`}
          >
            {'Go To Home'}
          </Link>
          <Link
            to={'/Partner-with-us'}
            className={`rounded-full bg-[--primary-color] text-[--black-bg-btn] flex gap-1 items-center arrow-effect-btn`}
          >
            {'Go To Partner With Us'}
          </Link>
        </div>
      </article>
    </section>
  );
};
export default SubmissionPopup;
