import { applyMiddleware, combineReducers, createStore } from 'redux';
import { thunk } from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import { adminReducer } from './Components/Reducers/AdminReducer';
import {
  appliedJobReducer,
  jobReducer,
} from './Components/Reducers/JobpostReducer';
//
import modeReducer from './Components/Reducers/DarkModeReducer';

const reducers = combineReducers({
  userAdmin: adminReducer,
  jobs: jobReducer,
  appliedjobs: appliedJobReducer,
  mode: modeReducer,
});
let initialState = {};
const middleware = [thunk];
const store = createStore(
  reducers,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);
export default store;
