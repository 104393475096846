const AboutISA = ({ heading, iconData }) => {
  return (
    <section className=" bg-[#1F0136] text-white my-12 p-[5%] ">
      <h2 className=" font-BaiJamjuree font-semibold text-xl lg:text-3xl">
        {heading}
      </h2>
      <div className="relative grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-8 md:gap-x-32 mt-4">
        {iconData.map((data, index) => (
          <div key={index} className={``}>
            {data.icon}
            {data?.heading && (
              <h4 className="mt-2 font-BaiJamjuree font-semibold text-lg lg:text-xl">
                {data?.heading}
              </h4>
            )}
            <p className="mt-3 text-base lg:text-lg opacity-70 font-BaiJamjuree font-medium">
              {data.des}
            </p>
          </div>
        ))}
        <div className="hidden md:block w-px h-full bg-[#ffffff36] absolute left-[50%] translate-x-[50%] mx-auto"></div>
      </div>
    </section>
  );
};
export default AboutISA;
