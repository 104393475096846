// axios_url.js

import axios from "axios";

const axiosInstance = axios.create({
  // baseURL: 'http://localhost:8080',
  // baseURL: 'https://backend.isaconsulting.com',
  baseURL: "https://productionbackend.isaconsulting.com",

  withCredentials: true,
});

export default axiosInstance;
