import PhoneInput from "react-phone-number-input";
import { Country } from "country-state-city";
import "react-phone-number-input/style.css";
import TermsAndConditionsPopup from "../../Pages/TermsAndConditionsPopup";
import { useState } from "react";
import CustomSpinner from "../CustomSpinner";
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from "formik";
import axiosInstance from "../../ulities/axios";
import { toast } from "react-toastify";
import { contactUsSchema } from "../../schemas";
import SubmissionPopup from "./SubmissionPopup";

const checkboxOptions = [
  { name: "dataAnalytics", label: "Data & Analytics services" },
  { name: "digitalSolution", label: "Digital Solutions" },
  { name: "bpoServices", label: "BPO Services" },
  { name: "industryDomain", label: "Industry domain solutions" },
  { name: "Others", label: "Others" },
];

const PartnerContactUsForm = () => {
  const [scrollableModal, setScrollableModal] = useState(false);
  const [isTermsChecked, setTermsChecked] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [signUpBtnDisabled, setSignUpBtnDisabled] = useState(true);
  const handleCheckboxChange = (event) => {
    setTermsChecked(event.target.checked);
  };
  const [loading, setloading] = useState(false);
  function onCaptchaChange(value) {
    setSignUpBtnDisabled(false);
  }

  const { values, handleBlur, handleChange, errors, touched, handleSubmit } =
    useFormik({
      initialValues: {
        role: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        country: "",
        company: "",
        jobTitle: "",
        industry: "",
        message: "",
        dataAnalytics: false, // Add checkbox fields as boolean
        digitalSolution: false,
        bpoServices: false,
        industryDomain: false,
        Others: false,
      },
      validationSchema: contactUsSchema,
      onSubmit: async (values, { setSubmitting, resetForm }) => {
        console.log("🚀 ~ onSubmit: ~ values:", values);
        try {
          setloading(true);
          const selectedCountry = Country.getAllCountries().find(
            (item) => item.isoCode === values?.country
          );

          const formData = new FormData();
          formData.append("role", values?.role);
          formData.append("firstName", values?.firstName);
          formData.append("lastName", values?.lastName);
          formData.append("phoneNumber", values?.phoneNumber);
          formData.append("email", values?.email);
          formData.append("country", selectedCountry?.name);
          formData.append("company", values?.company);
          formData.append("jobTitle", values?.jobTitle);
          formData.append("industry", values?.industry);
          formData.append("message", values?.message);
          formData.append("dataAnalytics", values?.dataAnalytics);
          formData.append("digitalSolution", values?.digitalSolution);
          formData.append("bpoServices", values?.bpoServices);
          formData.append("industryDomain", values?.industryDomain);
          formData.append("Others", values?.Others);

          const response = await axiosInstance.post(
            "user/partner-contact-us",
            formData,
            {
              headers: { "Content-Type": "application/json" },
            }
          );

          toast.success("Data submitted successfully");
          setShowPopup(true);
          setloading(false);
          setTermsChecked(false);
          resetForm();
        } catch (error) {
          setShowPopup(false);
          setloading(false);
          toast.error(
            error?.response?.data?.message ||
              "An error occurred while registration!"
          );
        }
      },
    });

  const handlePhoneNumberChange = (value) => {
    handleChange({
      target: {
        name: "phoneNumber",
        value: value,
      },
    });
  };
  return (
    <main className="px-[5%]">
      <h1 className="font-BaiJamjuree font-bold text-2xl lg:text-4xl mt-8">
        Partner With Us
      </h1>
      <p className="font-BaiJamjuree text-xl mt-2 lg:text-2xl">
        Get answers to your questions about ISA and our services.
      </p>
      <form onSubmit={handleSubmit} className="my-4 grid grid-cols-2 gap-4">
        <label className="flex flex-col gap-1 font-BaiJamjuree font-medium col-span-full">
          I am a/an...*
          <select
            name="role"
            value={values.role}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`bg-[--login-input-bg] focus:outline-none p-3 text-lg rounded-xl border-[1px] border-[--heading-color-1] placeholder:!text-[--heading-color-1] !text-[--heading-color-1] w-full ${
              errors.role && touched.role ? "border-red-500" : ""
            }`}
          >
            <optgroup className="bg-[--background-color]">
              <option value="">Please Select</option>
              <option value="Advisor/Analyst">Advisor/Analyst</option>
              <option value="Client">Client</option>
              <option value="Prospect">Prospect</option>
              <option value="Speaker">Speaker</option>
              <option value="Media">Media</option>
              <option value="Investor">Investor</option>
              <option value="Partner">Partner</option>
            </optgroup>
          </select>
          {errors.role && touched.role && (
            <span className="text-red-500 text-sm">{errors.role}</span>
          )}
        </label>
        <label className="flex flex-col gap-1 font-BaiJamjuree font-medium">
          First Name*
          <input
            type="text"
            name="firstName"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`bg-[--login-input-bg] focus:outline-none p-3 text-lg rounded-xl border-[1px] border-[--heading-color-1] placeholder:!text-[--heading-color-1] !text-[--heading-color-1] w-full ${
              errors.firstName && touched.firstName ? "border-red-500" : ""
            }`}
          />
          {errors.firstName && touched.firstName && (
            <span className="text-red-500 text-sm">{errors.firstName}</span>
          )}
        </label>
        <label className="flex flex-col gap-1 font-BaiJamjuree font-medium">
          Last Name*
          <input
            type="text"
            name="lastName"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`bg-[--login-input-bg] focus:outline-none p-3 text-lg rounded-xl border-[1px] border-[--heading-color-1] placeholder:!text-[--heading-color-1] !text-[--heading-color-1] w-full ${
              errors.lastName && touched.lastName ? "border-red-500" : ""
            }`}
          />
          {errors.lastName && touched.lastName && (
            <span className="text-red-500 text-sm">{errors.lastName}</span>
          )}
        </label>
        <label className="flex flex-col gap-1 font-BaiJamjuree font-medium">
          Company*
          <input
            type="text"
            name="company"
            value={values.company}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`bg-[--login-input-bg] focus:outline-none p-3 text-lg rounded-xl border-[1px] border-[--heading-color-1] placeholder:!text-[--heading-color-1] !text-[--heading-color-1] w-full ${
              errors.company && touched.company ? "border-red-500" : ""
            }`}
          />
          {errors.company && touched.company && (
            <span className="text-red-500 text-sm">{errors.company}</span>
          )}
        </label>
        <label className="flex flex-col gap-1 font-BaiJamjuree font-medium">
          Email*
          <input
            type="email"
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`bg-[--login-input-bg] focus:outline-none p-3 text-lg rounded-xl border-[1px] border-[--heading-color-1] placeholder:!text-[--heading-color-1] !text-[--heading-color-1] w-full ${
              errors.email && touched.email ? "border-red-500" : ""
            }`}
          />
          {errors.email && touched.email && (
            <span className="text-red-500 text-sm">{errors.email}</span>
          )}
        </label>
        <label className="flex flex-col gap-1 font-BaiJamjuree font-medium">
          Job Title*
          <input
            type="text"
            name="jobTitle"
            value={values.jobTitle}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`bg-[--login-input-bg] focus:outline-none p-3 text-lg rounded-xl border-[1px] border-[--heading-color-1] placeholder:!text-[--heading-color-1] !text-[--heading-color-1] w-full ${
              errors.jobTitle && touched.jobTitle ? "border-red-500" : ""
            }`}
          />
          {errors.jobTitle && touched.jobTitle && (
            <span className="text-red-500 text-sm">{errors.jobTitle}</span>
          )}
        </label>
        <label className="flex flex-col gap-1 font-BaiJamjuree font-medium">
          Phone number*
          <PhoneInput
            value={values?.phoneNumber}
            onChange={handlePhoneNumberChange}
            onBlur={handleBlur("phoneNumber")}
            placeholder={"Phone number"}
            name="phoneNumber"
            className={`bg-[--login-input-bg] focus:outline-none p-3 text-lg rounded-xl border-[1px] border-[--heading-color-1] placeholder:!text-[--heading-color-1] !text-[--heading-color-1] w-full ${
              errors.phoneNumber && touched.phoneNumber
                ? "border-red-500 border-2"
                : ""
            }`}
          />
          {errors.phoneNumber && touched.phoneNumber && (
            <span className="text-red-500 text-sm">{errors.phoneNumber}</span>
          )}
        </label>

        <label className="flex flex-col gap-1 font-BaiJamjuree font-medium col-span-full">
          Country*
          <select
            id="countries"
            value={values?.country}
            onChange={handleChange}
            onBlur={handleBlur}
            name="country"
            className={`bg-[--login-input-bg]  focus:outline-none p-3 text-lg rounded-xl border-[1px] border-[--heading-color-1] placeholder:!text-[--heading-color-1] !text-[--heading-color-1] w-full appearance-none select-opt-bg ${
              errors.country && touched.country ? "border-red-500" : ""
            }`}
          >
            <option value="" disabled>
              {"Select Country"}
            </option>
            {Country &&
              Country.getAllCountries().map((item) => (
                <option
                  className="text-[--primary-color]"
                  key={item.isoCode}
                  value={item?.isoCode}
                >
                  {item.name}
                </option>
              ))}
          </select>
          {errors?.country && touched?.country ? (
            <span className="text-sm text-red-600">{errors?.country}</span>
          ) : (
            ""
          )}
        </label>
        <label className="flex flex-col gap-1 font-BaiJamjuree font-medium col-span-full">
          Please choose your industry*
          <select
            name="industry"
            value={values.industry}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`bg-[--login-input-bg] focus:outline-none p-3 text-lg rounded-xl border-[1px] border-[--heading-color-1] placeholder:!text-[--heading-color-1] !text-[--heading-color-1] w-full ${
              errors.industry && touched.industry ? "border-red-500" : ""
            }`}
          >
            <optgroup className="bg-[--background-color]">
              <option value="">Please Select</option>
              <option value="Banking and Financial Services">
                Banking and Financial Services
              </option>
              <option value="Health">Health</option>
              <option value="Insurance">Insurance</option>
              <option value="Media and Entertainment">
                Media and Entertainment
              </option>
              <option value="Retail">Retail</option>
              <option value="Transportation and Logistics">
                Transportation and Logistics
              </option>
              <option value="Travel">Travel</option>
              <option value="Manufacturing">Manufacturing</option>
              <option value="Utilities">Utilities</option>
              <option value="Others">Others</option>
            </optgroup>
          </select>
          {errors.industry && touched.industry && (
            <span className="text-red-500 text-sm">{errors.industry}</span>
          )}
        </label>
        <p className="col-span-full font-BaiJamjuree font-medium text-xl">
          Solution you are looking for*
        </p>
        {checkboxOptions.map(({ name, label }) => (
          <label
            key={name}
            className="col-span-full font-BaiJamjuree font-medium text-xl flex items-center gap-2"
          >
            <input
              name={name}
              type="checkbox"
              checked={values[name]}
              onChange={handleChange}
              onBlur={handleBlur}
              className="appearance-none size-[20px] border-[1px] border-[--heading-color-1] rounded-full checked:bg-[#9617F9]"
            />
            {label}
          </label>
        ))}
        <label className="col-span-full font-BaiJamjuree font-medium text-xl flex flex-col gap-2">
          Message*
          <textarea
            name="message"
            value={values.message}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`bg-[--login-input-bg]  focus:outline-none p-3 text-lg rounded-xl border-[1px] border-[--heading-color-1] placeholder:!text-[--heading-color-1] !text-[--heading-color-1] w-full appearance-none select-opt-bg resize-none ${
              errors.message && touched.message ? "border-red-500" : ""
            }`}
          ></textarea>
          {errors.message && touched.message && (
            <span className="text-red-500 text-sm">{errors.message}</span>
          )}
        </label>
        <label className="col-span-full font-BaiJamjuree font-medium text-xl flex items-start gap-2">
          <input
            name="termsAndConditions"
            type="checkbox"
            checked={isTermsChecked}
            onChange={handleCheckboxChange}
            className="appearance-none size-[20px] flex-shrink-0 border-[1px] border-[--heading-color-1] rounded mt-1 checked:bg-[#9617F9]"
          />
          <p>
            I understand and agree that the information submitted in this form
            will be transmitted to, stored and processed by ISA, in accordance
            with their{" "}
            <span
              onClick={(e) => {
                e.preventDefault();
                setScrollableModal(true);
              }}
              className="underline cursor-pointer"
            >
              Terms & Conditions
            </span>
            .
          </p>
        </label>
        <ReCAPTCHA
          className="md:mt-4"
          sitekey="6LfmrHopAAAAANuE_T6B90-BKn4dvBIVo3AookXX"
          onChange={onCaptchaChange}
        />
        <button
          type="submit"
          disabled={!isTermsChecked || signUpBtnDisabled}
          className={`mt-6 rounded-full  bg-[--primary-color] text-[--black-bg-btn] flex gap-1 items-center arrow-effect-btn mx-auto grow-0 shrink-0 py-2 col-span-full ${
            !isTermsChecked || signUpBtnDisabled
              ? "pointer-events-none opacity-50"
              : ""
          }`}
        >
          {!loading ? "Send" : <CustomSpinner />}
        </button>
      </form>
      {scrollableModal && (
        <TermsAndConditionsPopup setScrollableModal={setScrollableModal} />
      )}

      {showPopup && <SubmissionPopup setShowPopup={setShowPopup} />}
    </main>
  );
};
export default PartnerContactUsForm;
