import { useState } from 'react';

const ImageWithText = ({ img, imgFirst, heading, pragraphContents }) => {
  const [glowStyle, setGlowStyle] = useState({ top: '50%', left: '50%' });

  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setGlowStyle({
      top: `${e.clientY - rect.top}px`,
      left: `${e.clientX - rect.left}px`,
    });
  };
  return (
    <main
      onMouseMove={handleMouseMove}
      style={{
        '--glow-top': glowStyle.top,
        '--glow-left': glowStyle.left,
      }}
      className="glow-effect-container p-[5%] pb-0 grid grid-cols-1 md:grid-cols-2 place-items-center gap-12"
    >
      <aside className={`max-w-xl ${imgFirst ? 'md:order-1' : 'md:order-2'}`}>
        <img src={img} alt="building" />
      </aside>
      <article
        className={`[&_p]:opacity-70 ${imgFirst ? 'md:order-2' : 'md:order-1'}`}
      >
        {heading && (
          <h1 className="opacity-100 font-BaiJamjuree text-2xl lg:text-4xl font-semibold">
            {heading}
          </h1>
        )}
        {pragraphContents}
      </article>
    </main>
  );
};
export default ImageWithText;
