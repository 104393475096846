import { FaNfcDirectional } from 'react-icons/fa6';
import { GrDocumentPerformance } from 'react-icons/gr';
import { HiCubeTransparent } from 'react-icons/hi';
import { SiSimpleanalytics } from 'react-icons/si';
import HowtoFindPlacement from '../../Components/Home/HowtoFindPlacement';
import AboutISA from '../../Components/Services/AboutISA';
import Hero from '../../Components/Services/Hero';
import ImageWithText from '../../Components/Services/ImageWithText';
import ServiceBanner from '../../Components/Services/ServiceBanner';
import Public_Cloud from '../../assets/Images/Services/Blockchain.webp';
import heroBg from '../../assets/Images/Services/Blockchain_Integration.png';
import skyscraper from '../../assets/Images/Services/Blockchain_Integration2.webp';
import CloudImg from '../../assets/Images/Services/Blockchain_Solutions.webp';
import Transforming from '../../assets/Images/Services/Blockchain_Technology.webp';
import Multicloud from '../../assets/Images/Services/Contract_Development.webp';
import cloud_strategy from '../../assets/Images/Services/Decentralized_Application.webp';
import Cloud_Platforms from '../../assets/Images/Services/Tokenization.webp';
import blockChainLogo from '../../assets/Images/Services/bloackchain-logo.png';

const aboutISA_Data = [
  {
    icon: <FaNfcDirectional size={60} color="#E578FF" />,
    heading: 'Operational Agility with Blockchain',
    des: 'Leverage blockchain technology to enhance operational agility, streamlining multiparty workflows and accelerating performance across your value chain.',
  },
  {
    icon: <GrDocumentPerformance size={60} color="#E578FF" />,
    heading: 'Innovative Revenue Streams',
    des: 'Unlock new monetization opportunities with smart contracts that ensure product authenticity and facilitate asset tokenization in new marketplaces.',
  },
  {
    icon: <SiSimpleanalytics size={60} color="#E578FF" />,
    heading: 'Continuous Improvement and Risk Management',
    des: 'Utilize blockchain for transparency and accountability, reducing disputes and minimizing risks through secure, shared processes.',
  },
  {
    icon: <HiCubeTransparent size={60} color="#E578FF" />,
    heading: 'Data-Driven Insights',
    des: 'Enhance decision-making with real-time, permissioned data from blockchain, driving optimal business outcomes.',
  },
];

const BlockchainForInnovation = () => {
  return (
    <>
      <Hero
        bgImg={heroBg}
        heading="Swift Implementation of AI Automation and Blockchain Technologies"
        para="Rapid AI and Blockchain Integration Solutions Empowering Businesses with Tailored Technologies in Just 90 Days"
      />
      <ImageWithText
        img={skyscraper}
        imgFirst={true}
        // heading="Harnessing Insurance Ecosystems to Navigate Change"
        pragraphContents={
          <>
            <p className="font-BaiJamjuree text-base lg:text-xl my-4">
              As organizations adapt to a continuously evolving environment,
              fostering collaboration and optimizing processes beyond internal
              boundaries is essential for sustainable growth. While
              transformation can be challenging, technology should facilitate
              progress rather than hinder it.
            </p>
            <p className="font-BaiJamjuree text-base lg:text-xl my-4">
              Our team of experts combines cutting-edge AI algorithms and
              machine learning models to provide tailored solutions for complex
              business challenges, helping your organization stay ahead of the
              curve.Enhance operational flexibility, connectivity, and unlock
              new revenue opportunities through reliable, comprehensive data
              sharing and workflow automation that extends beyond organizational
              limits. Discover how ISA is leading the way in fostering
              collaboration and driving innovation in the business landscape.
            </p>
          </>
        }
      />
      <AboutISA heading="About ISA in Blockchain" iconData={aboutISA_Data} />
      <ImageWithText
        img={CloudImg}
        imgFirst={true}
        heading="Your Trusted Partner for Blockchain Solutions"
        pragraphContents={
          <p className="font-BaiJamjuree text-base lg:text-xl mt-4">
            ISA combines deep industry knowledge with cutting-edge blockchain
            technologies to deliver tailored solutions that propel business
            growth. Our collaborative methodology ensures that our services
            align with your strategic goals, enabling you to harness the
            transformative power of blockchain for enhanced security,
            transparency, and efficiency.
          </p>
        }
      />
      <ServiceBanner
        heading="Blockchain Solutions"
        parag="ISA delivers tailored blockchain solutions that enhance transparency and security, empowering organizations to harness data for sustainable growth."
        logo={blockChainLogo}
      />
      <div className="p-[5%] pb-0">
        {/* <hr className="h-px bg-white70 mb-4" /> */}
        <h1 className="opacity-100 font-BaiJamjuree text-2xl lg:text-4xl font-semibold">
          Services
        </h1>
      </div>
      <ImageWithText
        img={Transforming}
        imgFirst={true}
        heading="Blockchain Technology Consulting"
        pragraphContents={
          <p className="font-BaiJamjuree text-base lg:text-xl my-4">
            Receive expert guidance to leverage blockchain technology for your
            organization. Our consultants identify optimization opportunities in
            your business processes, ensuring that blockchain aligns with your
            growth strategies and industry best practices.
          </p>
        }
      />
      <ImageWithText
        img={Multicloud}
        imgFirst={false}
        heading="Smart Contract Development"
        pragraphContents={
          <p className="font-BaiJamjuree text-base lg:text-xl my-4">
            Automate your transactions with custom smart contracts designed for
            security and transparency. Our solutions reduce risks and manual
            errors, facilitating seamless execution of agreements tailored to
            your business needs.
          </p>
        }
      />
      <ImageWithText
        img={cloud_strategy}
        imgFirst={true}
        heading="Decentralized Application (dApp) Development"
        pragraphContents={
          <p className="font-BaiJamjuree text-base lg:text-xl my-4">
            Develop innovative decentralized applications (dApps) that enhance
            user experiences and secure data management. Our dApps are tailored
            to your requirements, enabling you to engage effectively with
            customers and explore new business models.
          </p>
        }
      />
      <ImageWithText
        img={Public_Cloud}
        imgFirst={false}
        heading="Blockchain Integration Solutions"
        pragraphContents={
          <p className="font-BaiJamjuree text-base lg:text-xl my-4">
            Integrate blockchain technology into your existing systems smoothly
            and efficiently. We ensure interoperability between platforms,
            enhancing your operational efficiency in a decentralized
            environment.
          </p>
        }
      />
      <ImageWithText
        img={Cloud_Platforms}
        imgFirst={true}
        heading="Tokenization Services"
        pragraphContents={
          <p className="font-BaiJamjuree text-base lg:text-xl my-4">
            Unlock new revenue streams through asset tokenization, transforming
            physical assets into digital tokens. Our services facilitate trading
            and improve market access while ensuring compliance with industry
            standards.
          </p>
        }
      />
      <HowtoFindPlacement />
    </>
  );
};
export default BlockchainForInnovation;
