import Hero2 from '../Components/Home/Hero2';
import ISAHeadline from '../Components/TeamDetials/ISAHeadline';
import planImg from '../assets/Images/roll-out-plane.png';
import gatheringImg from '../assets/Images/isa-team-gathering.webp';
import Mission_values from '../assets/Images/Mission_values.png';
import Ethics_compliance from '../assets/Images/Ethics_compliance.png';
import Environmental from '../assets/Images/Environmental.png';
import Inclusion_diversity from '../assets/Images/Inclusion_diversity.png';
import OfferingsCard from '../Components/Home/OfferingsCard';
import TeamDetialsCarousel from '../Components/TeamDetials/TeamDetialsCarousel';

const TeamDetails = () => {
  return (
    <>
      <Hero2 isLink={false} />
      <ISAHeadline />
      <main className="px-[5%] [&_p]:opacity-70 [&_li]:opacity-70">
        <h1 className="font-BaiJamjuree text-2xl lg:text-4xl font-semibold">
          News! Teams Development & Training Program
        </h1>
        <p className="font-BaiJamjuree text-base lg:text-xl mt-4 [&_b]:font-BaiJamjuree">
          We are <b>excited to announce</b> the addition of <b>50 new</b> hires
          to our growing team! The <b>first group of 25</b> joined us on{' '}
          <b>September 30th, 2024</b>, and we can’t wait to welcome{' '}
          <b>the next wave of talented</b> individuals on{' '}
          <b>November 1st, 2024!</b>
        </p>
        <p className="font-BaiJamjuree text-base lg:text-xl my-4">
          This marks an exciting chapter of growth, and we look forward to
          seeing the amazing impact they will have on our journey ahead. Welcome
          aboard!
        </p>
        <p className="font-BaiJamjuree text-base lg:text-xl my-4">
          At ISA Consulting, we believe in the continuous growth and development
          of our teams. Our Comprehensive Development Program is designed to
          enhance communication, leadership, emotional intelligence, and client
          management skills. This structured program will enable employees to
          handle both internal and client-facing roles with confidence and
          professionalism.
        </p>
        <h2 className="font-BaiJamjuree text-2xl lg:text-3xl font-medium">
          Program Overview
        </h2>
        <p className="font-BaiJamjuree text-base lg:text-xl mt-4 [&_b]:font-BaiJamjuree">
          Our <b>paid internship</b> is 6 months long <b>raining program</b>{' '}
          combines interactive in-person and online sessions, practical
          exercises, and assignments that focus on professional communication,
          teamwork, emotional intelligence, and more. Participants will gain
          essential skills required for both internal collaboration and external
          client interactions, preparing them to excel in fast-paced, dynamic
          work environments.
        </p>

        <h2 className="font-BaiJamjuree text-2xl lg:text-3xl font-medium mt-4">
          Courses Offered
        </h2>
        <p className="font-BaiJamjuree text-base lg:text-xl mt-4 [&_b]:font-BaiJamjuree">
          Explore our Comprehensive Development Program, featuring a wide range
          of online courses designed to enhance your skills. In the
          Infrastructure category, you can learn with courses like Introduction
          to Cloud Computing on AWS and Complete Linux Bootcamp for Beginners.
          For those interested in Business Analysis, we offer Business Analysis
          Fundamentals and Product Management & Business Analysis. Dive into C#
          Development with C# Basics for Beginners and Advanced C# Programming,
          or explore Ruby Development through The Complete Ruby on Rails
          Developer Course and Ruby Programming for Beginners. Our Java
          Development course, Java In-Depth, provides comprehensive training for
          aspiring developers.
        </p>
        <p className="font-BaiJamjuree text-base lg:text-xl mt-4 [&_b]:font-BaiJamjuree">
          In Marketing Operations, you can take Marketing Operations Management
          and Marketing Analytics. For networking enthusiasts, we have
          Networking Fundamentals and Cisco CCNA 200-301. Database management
          skills can be sharpened with PostgreSQL for Everybody and SQL for Data
          Science. Our UI/UX Design offerings include User Experience Design
          Fundamentals and The Ultimate Guide to UX Design, while Python
          Programming courses such as Complete Python Bootcamp and Python for
          Data Science are perfect for those looking to delve into programming
          and data analysis.
        </p>

        <h2 className="font-BaiJamjuree text-2xl lg:text-3xl font-medium mt-4">
          Training Plan
        </h2>

        <h6 className="font-BaiJamjuree text-lg lg:text-xl font-medium mt-2">
          In-Person Sessions (1 per week)
        </h6>
        <p className="font-BaiJamjuree text-base lg:text-xl my-2 [&_b]:font-BaiJamjuree">
          Held weekly in Kosovo, these 3-hour interactive sessions provide
          hands-on learning in key areas. Topics include:
        </p>
        <ul className="list-disc space-y-1 text-base lg:text-lg ml-8">
          <li>
            Intro to Communication: Understanding the importance of
            communication for IT professionals.
          </li>
          <li>
            Verbal Communication: Techniques for speaking clearly and
            confidently.
          </li>
          <li>
            Non-Verbal Communication: Using body language, tone, and facial
            expressions effectively.
          </li>
          <li>
            Active Listening: Techniques to develop empathy and provide
            thoughtful, effective responses.
          </li>
          <li>
            Emotional Intelligence: Managing your own emotions while
            understanding others' emotions in the workplace.
          </li>
          <li>
            Conflict Resolution: Effective strategies for resolving conflicts
            and maintaining team harmony.
          </li>
          <li>
            Teamwork: Building trust, collaboration, and cohesiveness within
            teams.
          </li>
          <li>
            Problem-Solving & Decision-Making: Strategies to tackle challenges
            and make informed decisions.
          </li>
          <li>
            Presentation Skills: Structuring and delivering compelling
            presentations.
          </li>
          <li>
            Difficult Conversations: Approaches for handling sensitive or
            challenging conversations professionally.
          </li>
          <li>
            Adaptability: Building resilience and flexibility in fast-changing
            environments.
          </li>
          <li>
            Virtual Meeting Etiquette: Best practices for professional behavior
            in virtual meetings, including managing technical aspects and clear
            communication.
          </li>
          <li>
            Effective Client Communication: Strategies for understanding client
            needs, managing expectations, and conducting productive client
            meetings.
          </li>
          <li>
            Cross-Cultural Communication: Respecting and adapting to different
            cultures in professional interactions.
          </li>
          <li>
            Review & Feedback Session: Participants will apply the skills
            they've learned through group projects, followed by mentor feedback
            and reflection.
          </li>
        </ul>
        <h6 className="font-BaiJamjuree text-lg lg:text-xl font-medium mt-4">
          Online Sessions (1 per week)
        </h6>
        <p className="font-BaiJamjuree text-base lg:text-xl my-2 [&_b]:font-BaiJamjuree">
          Our online sessions allow flexibility while providing robust learning
          experiences. Each 3-hour online session focuses on self-paced
          learning, collaboration, and practical application, covering:
        </p>
        <ul className="list-disc space-y-1 text-base lg:text-lg ml-8">
          <li>
            English Communication: Emphasis on professional etiquette when
            communicating online, handling conversations with managers, and
            maintaining respect.
          </li>
          <li>
            AI & Machine Learning in E-Discovery (2nd Month): Exploring how AI
            and ML technologies are transforming e-discovery processes.
          </li>
          <li>
            Introduction to the Platform & Products (2nd-3rd Month):
            Understanding how to use the cloud-based e-discovery platform to
            simplify document and data management.
          </li>
        </ul>
        <p className="font-BaiJamjuree text-base lg:text-xl my-2 mt-4 [&_b]:font-BaiJamjuree">
          Assignments will include:
        </p>
        <ul className="list-disc space-y-1 text-base lg:text-lg ml-8">
          <li>
            Reflection Tasks: Participants will reflect on their communication
            challenges and areas for improvement.
          </li>
          <li>
            Group Exercises: Teamwork and problem-solving activities to foster
            collaboration.
          </li>
          <li>
            Video-Based Role-Play Submissions: Practical role-playing exercises
            submitted online, with mentor feedback provided.
          </li>
          <li>
            Interactive Quizzes & Surveys: Online assessments to reinforce
            learning and track progress.
          </li>
        </ul>
        <img
          src={planImg}
          alt="Plan"
          className="my-2 w-full h-full max-w-5xl max-h-[500px] block mx-auto"
        />
        <h2 className="font-BaiJamjuree text-2xl lg:text-3xl font-medium mt-4">
          Training Schedule
        </h2>
        <ul className="list-disc space-y-1 text-base lg:text-lg ml-8">
          <li>Total Program Duration: 12 weeks (3 months)</li>
          <li>
            In-Person Sessions: 1 per week (3 hours per session), held in Kosovo
          </li>
          <li>
            Online Sessions: 1 per week (3 hours per session), flexible timing
          </li>
        </ul>
        <h2 className="font-BaiJamjuree text-2xl lg:text-3xl font-medium mt-4">
          Training Mentor
        </h2>
        <p className="font-BaiJamjuree text-base lg:text-xl my-2 [&_b]:font-BaiJamjuree">
          Arlinda Çausholli
        </p>
        <p className="font-BaiJamjuree text-base lg:text-xl my-2 [&_b]:font-BaiJamjuree">
          Arlinda Çausholli brings over 25 years of experience as a journalist,
          publicist, and communication strategist. As the Founder of “Fit For
          Future”, a comprehensive program focused on communication and
          development, she is dedicated to improving communication skills,
          fostering a professional attitude, and promoting leadership. Her
          impressive background includes:
        </p>
        <ul className="list-disc space-y-1 text-base lg:text-lg ml-8">
          <li>TEDx Speaker and Youth/Business Mentor</li>
          <li>
            Founder of Fit For Future, focusing on communication skills,
            professional human behavior, and personal development.
          </li>
          <li>
            Ambassador of World Vision for Albania & Kosovo, raising awareness
            and supporting children with disabilities.
          </li>
          <li>
            Recognized with the award "The Woman Who Changes Albania" for her
            commitment to societal improvement.
          </li>
          <li>
            Extensive Media Experience: Worked with Gazeta Shqiptare, Associated
            Press, Corriere della Sera, and Tirana International Airport for
            over 16 years.
          </li>
        </ul>
        <p className="font-BaiJamjuree text-base lg:text-xl my-2 [&_b]:font-BaiJamjuree">
          Her leadership in the Comprehensive Teams Development Program will
          guide participants in mastering communication strategies, leadership
          skills, and emotional intelligence needed for professional success.
        </p>
        <TeamDetialsCarousel />
      </main>

      <main className="px-[5%] my-8 grid grid-cols-1 md:grid-cols-2 gap-10 insight-leading-edge-container">
        <h2 className="col-span-full font-BaiJamjuree text-2xl lg:text-4xl font-semibold mt-4">
          What we stand for
        </h2>
        <OfferingsCard
          img={Mission_values}
          heading="Mission and values"
          des="Our mission and values drive our people and impact our customers and communities."
        />
        <OfferingsCard
          img={Ethics_compliance}
          heading="Ethics and compliance"
          des="We act with integrity."
        />
        <OfferingsCard
          img={Environmental}
          heading="Environmental, social and governance"
          des="We are committed to sustainable and responsible business practices."
        />
        <OfferingsCard
          img={Inclusion_diversity}
          heading="Inclusion and diversity"
          des="We work as a team globally and locally."
        />
      </main>
    </>
  );
};
export default TeamDetails;
