/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
const FullPageSpinner = () => {
  return (
    <div className="w-full h-full fixed top-0 left-0   bg-black/5  z-2">
      <div className="flex justify-center items-center mt-[50vh]">
        {/* <img src={LoaderImg} alt="Loader Image" /> */}
        <div className="full-page-loader"></div>
      </div>
    </div>
  );
};

export default FullPageSpinner;
