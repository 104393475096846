import { GiSpeedometer, GiThreeFriends } from 'react-icons/gi';
import { GrDocumentPerformance } from 'react-icons/gr';
import { HiCubeTransparent } from 'react-icons/hi';
import { PiHandshakeLight } from 'react-icons/pi';
import { TiGroupOutline } from 'react-icons/ti';
import HowtoFindPlacement from '../../Components/Home/HowtoFindPlacement';
import AboutISA from '../../Components/Services/AboutISA';
import Hero from '../../Components/Services/Hero';
import ImageWithText from '../../Components/Services/ImageWithText';
import ServiceBanner from '../../Components/Services/ServiceBanner';
import Cloud_Advisory from '../../assets/Images/Services/Cloud_Advisory.png';
import Cloud_Platforms from '../../assets/Images/Services/Cloud_Platforms.webp';
import Cloud_and_Infrastructure from '../../assets/Images/Services/Cloud_and_Infrastructure.png';
import CloudImg from '../../assets/Images/Services/End-to-End_Cloud.webp';
import Multicloud from '../../assets/Images/Services/Multicloud.webp';
import Public_Cloud from '../../assets/Images/Services/Public_Cloud.webp';
import Transforming from '../../assets/Images/Services/Transforming.webp';
import cloud_strategy from '../../assets/Images/Services/cloud_strategy.webp';
import heroBg from '../../assets/Images/Services/hero_cloud_solutions.webp';
import skyscraper from '../../assets/Images/Services/skyscraper.webp';

const aboutISA_Data = [
  {
    icon: <PiHandshakeLight size={60} color="#E578FF" />,
    heading: '',
    des: 'Increased speed and cut IT operating costs up to 30% for large enterprise customers',
  },
  {
    icon: <GrDocumentPerformance size={60} color="#E578FF" />,
    heading: '',
    des: 'We facilitate seamless cloud migrations that enhance performance.',
  },
  {
    icon: <GiSpeedometer size={60} color="#E578FF" />,
    heading: '',
    des: 'We conduct thousands of public cloud migrations each year.',
  },
  {
    icon: <HiCubeTransparent size={60} color="#E578FF" />,
    heading: '',
    des: 'We support a diverse range of managed cloud customers.',
  },
  {
    icon: <GiThreeFriends size={60} color="#E578FF" />,
    heading: '',
    des: 'Close partnerships with Amazon Web Services (AWS), Google Cloud, Microsoft and VMware',
  },
  {
    icon: <TiGroupOutline size={60} color="#E578FF" />,
    heading: '',
    des: 'Innovative full-stack managed platform services for SAP, supporting more than 5 million users',
  },
];

const CloudSolutions = () => {
  return (
    <>
      <Hero
        bgImg={heroBg}
        heading="Transform Your Business with Flexible Cloud Solutions"
        para="Make timely technology investments on the right platforms to drive
        innovation, boost customer loyalty, and foster business growth"
      />
      <ImageWithText
        img={skyscraper}
        imgFirst={true}
        pragraphContents={
          <>
            <p className="font-BaiJamjuree text-base lg:text-xl my-4">
              Achieve the business outcomes you desire. When implemented
              effectively, cloud technology can yield up to three times the
              return on investment, delivering faster results with reduced
              costs, risks, and disruptions. ISA guides you in making informed
              decisions about which applications to migrate and when. With ISA's
              cloud solutions, you can optimize data usage while maintaining a
              secure environment.{' '}
            </p>
            <p className="font-BaiJamjuree text-base lg:text-xl">
              Our experience in managing hybrid IT for leading global companies
              enables us to support your cloud strategy, including migrating
              thousands of workloads and modernizing numerous applications.
            </p>
          </>
        }
      />
      <AboutISA heading="About ISA in cloud" iconData={aboutISA_Data} />
      <ImageWithText
        img={CloudImg}
        imgFirst={true}
        heading="Expert in End-to-End Cloud Management Solutions"
        pragraphContents={
          <p className="font-BaiJamjuree text-base lg:text-xl mt-4">
            ISA provides comprehensive cloud infrastructure management services
            across platforms like Microsoft Azure and AWS. We support businesses
            at every stage of their cloud journey, focusing on enhancing cost
            efficiency, agility, and resiliency. Our expert team ensures optimal
            performance and scalability, helping clients fully leverage the
            potential of the cloud.
          </p>
        }
      />
      <ServiceBanner
        heading="Driving Cloud Innovation and Agility"
        parag="Empowering businesses to optimize their cloud investments and enhance efficiency through advanced cloud solutions and expert guidance."
      />
      <ImageWithText
        img={Transforming}
        imgFirst={false}
        heading="Transforming Alstom's Cloud Strategy"
        pragraphContents={
          <>
            <p className="font-BaiJamjuree text-base font-medium lg:text-xl my-4">
              Key Objectives and Implementation
            </p>
            <p className="font-BaiJamjuree text-base lg:text-xl my-4">
              To enhance agility and support future growth, ISA developed a
              tailored cloud strategy for Alstom. The primary objective was to
              establish a fast, efficient, and flexible infrastructure,
              leveraging the public cloud as a crucial component of this
              transformation. By focusing on seamless integration and
              adaptability, Alstom aims to optimize operations and drive
              innovation across its services.
            </p>
          </>
        }
      />
      <ImageWithText
        img={cloud_strategy}
        imgFirst={true}
        heading="ISA formulates a comprehensive cloud strategy for Alstom"
        pragraphContents={
          <>
            <p className="font-BaiJamjuree text-base lg:text-xl my-4">
              “To enhance business agility and establish a foundation for future
              growth, we required a rapid, efficient, and adaptable
              infrastructure. The public cloud is essential for this
              transformation.”
            </p>
            <h6 className="font-BaiJamjuree text-base font-medium lg:text-xl">
              Stephane Detruiseux
            </h6>
            <p className="font-BaiJamjuree text-base lg:text-xl">
              Chief Information Security Officer and Technology VP Alstom
            </p>
          </>
        }
      />
      <div className="p-[5%] pb-0">
        <hr className="h-px bg-white70 mb-4" />
        <h1 className="opacity-100 font-BaiJamjuree text-2xl lg:text-4xl font-semibold">
          Services
        </h1>
      </div>
      <ImageWithText
        img={Multicloud}
        imgFirst={true}
        heading="Private, Hybrid & Multicloud"
        pragraphContents={
          <p className="font-BaiJamjuree text-base lg:text-xl my-4">
            Enable rapid self-service provisioning and management across various
            clouds and hybrid IT environments. Rely on ISA to effectively
            orchestrate private and public clouds, servers, and mainframes as a
            unified system.
          </p>
        }
      />
      <ImageWithText
        img={Public_Cloud}
        imgFirst={false}
        heading="Public Cloud"
        pragraphContents={
          <p className="font-BaiJamjuree text-base lg:text-xl my-4">
            Our public cloud migration and managed services leverage strong
            partnerships with top providers like Amazon Web Services (AWS),
            Google Cloud Platform, and Microsoft Azure, enabling us to deliver
            tailored solutions for your cloud needs.
          </p>
        }
      />
      <ImageWithText
        img={Cloud_Platforms}
        imgFirst={true}
        heading="Cloud Platforms"
        pragraphContents={
          <p className="font-BaiJamjuree text-base lg:text-xl my-4">
            Boost business agility and innovation by migrating applications to
            modern cloud platforms. Our managed services for SAP and containers
            offer cloud consulting and solutions for rapid, flexible deployment
            of cloud-enabled applications.
          </p>
        }
      />
      <div className="insight-leading-edge-container">
        <ImageWithText
          img={Cloud_Advisory}
          imgFirst={false}
          heading="Cloud Advisory & Migration Services"
          pragraphContents={
            <p className="font-BaiJamjuree text-base lg:text-xl my-4">
              Utilize our comprehensive advisory, transformation, and migration
              services, enhanced by automated tools and proven methodologies for
              fast, reliable delivery.
            </p>
          }
        />
        <ImageWithText
          img={Cloud_and_Infrastructure}
          imgFirst={true}
          heading="ISA Recognized for Excellence in Cloud and Infrastructure Services"
          pragraphContents={
            <p className="font-BaiJamjuree text-base lg:text-xl my-4">
              ISA is acknowledged for delivering innovative cloud and
              infrastructure solutions that feature advanced capabilities,
              including automation and self-healing technologies. Our commitment
              to providing tailored cloud solutions supports diverse business
              needs, ensuring optimal performance and efficiency for clients in
              the private and hybrid cloud segments.
            </p>
          }
        />
      </div>
      <HowtoFindPlacement />
    </>
  );
};
export default CloudSolutions;
