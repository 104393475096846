export const JOB_POST_REQUEST = " JOB_POST_REQUEST";
export const JOB_POST_SUCCESS = " JOB_POST_SUCCESS";
export const JOB_POST_FAIL = " JOB_POST_FAIL";

export const LOAD_JOB_REQUEST = " LOAD_JOB_REQUEST";
export const LOAD_JOB_SUCCESS = " LOAD_JOB_SUCCESS";
export const LOAD_JOB_FAIL = " LOAD_JOB_FAIL";

export const GET_APPLIED_JOBS_REQUEST = " GET_APPLIED_JOBS_REQUEST";
export const GET_APPLIED_JOBS_SUCCESS = " GET_APPLIED_JOBS_SUCCESS";
export const GET_APPLIED_JOBS_FAIL = " GET_APPLIED_JOBS_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

// added by Amir
export const SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY";
export const SET_SELECTED_JOB_TYPE = "SET_SELECTED_JOB_TYPE";
export const SET_SELECTED_JOB_SKILLS = "SET_SELECTED_JOB_SKILLS";
